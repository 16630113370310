// Modal.js
import React, { useState } from "react";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";
const InviteModal = ({ isOpen, onClose, onAdd, invCount }) => {
  
  const [textFields, setTextFields] = useState([""]); // State to store an array of text fields
  const [inviteError, setInviteError] = useState(null);

  const invitepopuphandleAdd = () => {
    
if (textFields.length < parseInt(invCount)) {
  setTextFields([...textFields, ""]); // Add a new empty text field
} else {
  alert(`You can Invite ${invCount} People at a time`);
}

  };

  const invitepopuphandleTextChange = (index, value) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value;
    setTextFields(updatedTextFields);
  };

  const invitepopuphandleDelete = (index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields.splice(index, 1);
    setTextFields(updatedTextFields);
  };

const accessToken = JSON.parse(localStorage.getItem("user"));
const btoken = accessToken.access;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${String(btoken)}`,
};
const formData={
  dataFields:textFields
}
const getPhoneNumber = async () => {
    console.log(formData)
    const isInvalid = textFields.some(text => {
      const trimmedText = text.trim(); // Remove leading and trailing spaces
      return trimmedText === '' || trimmedText.replace(/\D/g, '').length !== 10; // Check if empty or not exactly 10 digits
    });

    // If any input field is empty, alert the user and prevent sending the invite
    if (isInvalid) {
      setInviteError("Please fill in all phone numbers with exactly 10 digits")
      // alert('Please fill in all phone numbers with exactly 10 digits');
      return;
    }

  try {
    
    const response = await axios.post(
      `${baseURL}/userapi/referralinviteview/`,
      formData,
      { headers: headers }
    );
    //setSuccessMessage("successfully updated");
    alert("Your invitation has been successfully sent")
    window.location.reload();
    console.log("API Response:", response.data);
  } catch (error) {
    // Handle errors
    //setErrorMessage("something went wrong");
    console.error("Error submitting form:", error);
  }

};

  return (
    <div className={`InvitePopupmodal ${isOpen ? "open" : ""}`}>
      <div className="InvitePopupmodal-content">
        <span className="InvitePopupclose" onClick={onClose}>
          &times;
        </span>
        {inviteError !== null &&(<span style={{ color: 'red' }}>{inviteError}</span>)}
        
        <h4>Enter Phone number with Country code</h4>

        <div className="invite-textfield-wrap">
        {textFields.map((text, index) => (
          <div key={index} className="AccountPopupField">
           <button className="country-code-1">+91</button> <input
              type="text"
              placeholder="Enter 10 Digit Phone number"
              value={text}
              onChange={(e) => invitepopuphandleTextChange(index, e.target.value)}
            />
            &nbsp;<button className="TockensButton" onClick={() => invitepopuphandleDelete(index)}>Delete</button>
          </div>
        ))}
      </div>
      <button className="btn-2" onClick={invitepopuphandleAdd}>Add Phone number</button> &nbsp;
      <button className="btn-1" onClick={getPhoneNumber}>Send Invite</button>
    </div>
    </div>
  );
};

export default InviteModal;