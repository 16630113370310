import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";


const Logout = () => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();
  
    const handleLogout = async () => {
      try {
        await logOut();
        navigate("/");
      } catch (error) {
        console.log(error.message);
      }
    };
  
    // You can trigger the logout automatically
    useEffect(() => {
      handleLogout();
    }, []); // The empty dependency array ensures that the effect runs only once when the component mounts
  
   
    return null;
  };
  
  export default Logout;

