import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo.png";
import "./Header.css";
function Header() {
  const [showNavbar, setShowNavbar] = useState(false);
  const navbarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleConfirmExit = () => {
    const confirmed = window.confirm("Are you sure you want to exit the game?");
    if (confirmed) {
      // Redirect to dashboard page
      // Replace 'dashboard' with your actual dashboard page URL
      window.location.href = "/dashboard";
    }
  };

  return (
    <div>
      <div className="header">
        <div class="wrapper">
          <div class="nav-wrapper">
            <div class="nav-1">
              <NavLink to="/">
                <img src={Logo} class="logo-div" alt={Logo} width="170" />
              </NavLink>
            </div>
            <div class="nav-2">
              <div class="nav-main">
                <button className="btn-3" onClick={handleConfirmExit}>
                  Exit-Game
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

export default Header;
