import React, { useEffect, useState, useMemo } from "react";
import InnerBanner from "./InnerBanner.js";
import "./Styles/ContactUs.css";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js"; // Assuming baseURL is already defined correctly

function MyOrdersList() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // State to store filtered orders
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const [errorMessage, setErrorMessage] = useState(""); // State to handle error messages
  const [tokens, setTokens] = useState(0); // State to store tokens
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [noTransactionsMessage, setNoTransactionsMessage] = useState(""); // State to handle no transactions message

  const ordersPerPage = 5; // Number of orders per page
  const accessTokenForReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenForReferal ? accessTokenForReferal.access : ""; // Ensure access token is not null

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${btoken}`,
    }),
    [btoken]
  ); // Only change if btoken changes

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${baseURL}/tockens_api/product_orders_list/`,
          {
            method: "GET",
            headers: headers,
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Fetched data:", data);

        if (Array.isArray(data)) {
          setOrders(data);
          setFilteredOrders(data);
          setLoading(false); // Loading complete
        } else {
          setOrders(data.orders || []);
          setFilteredOrders(data.orders || []);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [headers]);

  useEffect(() => {
    const fetchHomeDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/userapi/gethomedetails/`, {
          headers: headers,
        });

        const { tockenslist } = response.data;
        setTokens(tockenslist);
      } catch (error) {
        // Handle error
      }
    };

    fetchHomeDetails();
  }, [headers]);

  // Handle search based on dates
const onClickOrderSearch = () => {
  if (!startDate || !endDate) {
    setErrorMessage("*Both start and end dates are required.");
    return;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  if (end < start) {
    setErrorMessage("*End date cannot be earlier than start date.");
    return;
  }
  setErrorMessage(""); // Clear any previous error

  const formattedStartDate = start.toISOString().split("T")[0];
  const formattedEndDate = end.toISOString().split("T")[0];

  axios
    .get(
      `${baseURL}/tockens_api/product_orders_list/${formattedStartDate}/${formattedEndDate}/`,
      { headers: headers }
    )
    .then((response) => {
      console.log("Full response data:", response.data); // Log full response
      const transactions = response.data.orders;
      console.log(transactions)

      if (transactions && transactions.length > 0) {
        setOrders(transactions); // Set orders for pagin'ation
        setFilteredOrders(transactions); // Set filtered orders for display
        console.log("filteredOrders" , transactions)
        setNoTransactionsMessage("");
      } else {
        setOrders([]);
        setFilteredOrders([]); // Clear filtered orders if no match
        setNoTransactionsMessage(
          `No transactions found between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`
        );
      }
    })
    .catch((error) => {
      setOrders([]);
      setFilteredOrders([]); // Clear filtered orders on error
      setNoTransactionsMessage(
        `No transactions are available between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`
      );
      console.error("Error fetching data:", error);
    });
};

const handleClearOrders = () => {
  setStartDate("");
  setEndDate("");
  setErrorMessage("");
  setNoTransactionsMessage(""); // Clear any messages

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/tockens_api/product_orders_list/`, {
      headers: headers, // Set headers correctly
      });

      // Set all orders and filtered orders back to the original data
      setOrders(response.data.orders);
      setFilteredOrders(response.data.orders); // Reset to all orders
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      setError("Error fetching data. Please try again."); // Set an error message to the state
    }
  };

  // Call fetchData to reload the data
  fetchData();
}



  // Handle pagination for filtered orders or all orders
  const currentData = filteredOrders.length > 0 ? filteredOrders : orders;
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = currentData.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(currentData.length / ordersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <div className="wrapper-sec-1">
            <h1>Shopping Orders</h1>
            <h2 className="tbalnce">
              Balance Tockens: <span className="tockens-box">{tokens}</span>
            </h2>
          </div>
          <div className="search-i-main">

            <div className="search-i-1">
              <h5>Start Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="search-i-1">
              <h5>End Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            {/* <div className="search-i-1">
              <button className="button-search" onClick={onClickOrderSearch}>
                Search
              </button>
            </div> */}
            <div className="search-i-1">
              <div className="search-btns">
                <button className="button-search" onClick={onClickOrderSearch}>
                  Search
                </button>
                <button className="button-clear" onClick={handleClearOrders}>
                  Clear
                </button>
              </div>
            </div>

          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {startDate && endDate && !errorMessage && (
            <div>
              <p>
                Showing orders from{" "}
                <strong>{new Date(startDate).toLocaleDateString()}</strong> to{" "}
                <strong>{new Date(endDate).toLocaleDateString()}</strong>
              </p>
            </div>
          )}

          {loading ? (
            <p className="Loading">Loading...</p>
          ) : currentOrders.length > 0 ? (
            currentOrders.map((order , index) => (
              <div className="your-order-main" key={order.product_id || index}>
                <div className="your-order-1-main">
                  <div className="your-order-1-1">
                    Order Id &nbsp;:&nbsp;{order.product_id}
                  </div>
                  <div className="your-order-1-1">
                    Order Date &nbsp;:&nbsp;
                    {new Date(order.add_date_time).toLocaleDateString("en-IN")}
                  </div>
                  <div className="your-order-1-1">
                    Paid Tockens&nbsp;:&nbsp;{order.used_tockens}
                  </div>
                </div>
                <div className="your-order-2-1-main">
                  <div className="your-order-2-1">
                    <img
                      src={`${baseURL}/media/${order.product_image}`}
                      alt={order.product_name}
                    />
                  </div>
                  <div className="your-order-2-2">
                    <h4>{order.product_name}</h4>
                    <p>
                      Email: {order.email}
                      <br />
                      Phone: {order.phone_number}
                    </p>
                  </div>
                  <div className="your-order-2-3">
                    <h5>Shipping Address</h5>
                    <p>
                      {order.full_name},<br />
                      {order.present_address}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-orders">
              {noTransactionsMessage || "No orders found."}
            </div>
          )}

          {/* Pagination Controls */}
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrdersList;
