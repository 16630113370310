
import React from 'react';
import "./Styles/ExtraOffers.css";
// import successlogo from "./images/sucess-msg.png"

function DealsError() {
  
  const handleClick = () => {
    const redirectUrl = `/`;
    window.location.href = redirectUrl;
  };


  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
      
          <div className="claim-page-bg">
          {/* <img src={successlogo} alt="logo" /> */}
          <p>You have already used this. Please wait 24 hours before you can use it again.</p>
            <h3>
       
            </h3>
            <button class="button-orange-1 w-80" onClick={handleClick}>Back to Home</button>
          </div>     
        </div>
      </div>
    </>
  );
}

export default DealsError;
