import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import InnerBanner from "./InnerBanner";
import "./Styles/Profile.css";
import ActiveImg from "./images/active.png";

import { baseURL } from "../services/Aut.post.js";

import Modal from "react-bootstrap/Modal";
import AvatarEditor from "react-avatar-edit";
import ProfilepImg from "./images/profile-img.png";
import ProfilepEdit from "./images/profile-edit.png";


const ProfilePicModal = () => {
   
  const [selectedFile, setSelectedFile] = useState(null);
  const defaultProfilePic = ProfilepImg;
  const [pshowModal, setpShowModal] = useState(false);
  const [ppreview, setpPreview] = useState(null);
  const [pprofilePic, setpProfilePic] = useState(defaultProfilePic);

  const handlepClose = () => {
    setpShowModal(false);
    setpPreview(null);
  };
  const handlepShow = () => setpShowModal(true);
  const handlepUpload = (editor) => {
    if (editor) {
      setpPreview(editor);
    }
  };
  const handlepSave = () => {
    if (ppreview) {


      console.log("saved image is:", pprofilePic)

      console.log("saved image is now:", ppreview)


    //set file upload on change
    
    fetch(`${baseURL}/userapi/getimageuploadview/`, {
      method: "POST",
      body: ppreview,
      headers: {
        Authorization: `Bearer ${String(btoken)}`,
        //'Content-Type': 'application/json',
        // Add other headers as needed
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Upload successful:", data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });

    //end file uplaod
      
      setpProfilePic(ppreview);
      handlepClose();

    }
  };

  const accessToken = JSON.parse(localStorage.getItem("user"));
  console.log(accessToken.access);
  const btoken = accessToken.access;
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Please select a file.");
      return;
    }

    //set file upload on change
    const formData = new FormData();
    formData.append("file", file);
    fetch(`${baseURL}/userapi/getimageuploadview/`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${String(btoken)}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Upload successful:", data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });

    //end file uplaod
  };
  const handleUpload = () => {
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    fetch(`${baseURL}/userapi/getimageuploadview/`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${String(btoken)}`,
        //'Content-Type': 'application/json',
        // Add other headers as needed
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Upload successful:", data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });
  };
  //end image upload

  const { logOut, user } = useUserAuth();

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  //User get Details

  const [userData, setUserData] = useState();

  const getAllPrivatePosts = async () => {
    try {
      const response = await fetch(`${baseURL}/userapi/userdetails/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${String(btoken)}`,
          "Content-Type": "application/json",
          // Add other headers as needed
        },
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error! Status: ${response.status}`);
      // }
      const data = await response.json();
      console.log(data);
      return data; // You can return the data or perform other actions
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllPrivatePosts();
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <InnerBanner />

      <div className="profile-bg-1">
        <div className="wrapper">
          <h1>My Profile</h1>
          <div className="profile-sec-main">
            <div className="profile-sec-1">
              <div className="container">
                <div className="ProfilepEdit-main">
                  <div className="mt-3">
                    <img src={pprofilePic} alt="Profile" className="w-100" />
                  </div>
                  <div className="ProfilepEdit">
                    <img
                      src={ProfilepEdit}
                      alt={ProfilepEdit}
                      onClick={handlepShow}
                    />
                  </div>
                </div>

                <Modal
                  show={pshowModal}
                  onHide={handlepClose}
                  style={{
                    background: "transparent",
                    border: "0px",
                    boxShadow: "none",
                  }}
                >
                  <Modal.Header
                    closeButton
                    style={{ border: "0px", padding: "10px" }}
                  ></Modal.Header>
                  <Modal.Body>
                    <div
                      className="container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "250px",
                          height: "250px",
                          border: "2px dashed #ddd",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AvatarEditor
                          width={250}
                          height={250}
                          onCrop={handlepUpload}
                          onClose={() => setpPreview(null)}
                          // Add other props as needed
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handlepClose}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handlepSave}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>

            <div className="profile-sec-2">
              <div className="verification">
                Verification Status:{" "}
                <span>
                  <img src={ActiveImg} alt={ActiveImg} />
                  Verified
                </span>
              </div>
              <div>
                <h4>pradeep</h4>
                <hr></hr>
                <h5>+918099994857</h5>
                <h5>pradeep@eparivartan.com</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default ProfilePicModal;
