// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const firebaseConfig = {
  // apiKey: "AIzaSyB4zRwEqyd8xLZgxu_EM_YFFsOmtuFNX04",
  // authDomain: "goodluckcharm.firebaseapp.com",
  // projectId: "goodluckcharm",
  // storageBucket: "goodluckcharm.appspot.com",
  // messagingSenderId: "680354278757",
  // appId: "1:680354278757:web:4061d77fbe9d0e48b9c58c",
  // apiKey: "AIzaSyD2kG1slO_mQCi3mehcNMq0XMaa_5Pm-84",
  // authDomain: "bbphase2-6cb5a.firebaseapp.com",
  // projectId: "bbphase2-6cb5a",
  // storageBucket: "bbphase2-6cb5a.appspot.com",
  // messagingSenderId: "974806987324",
  // appId: "1:974806987324:web:09a1e0f2ac6d343a9fc939",
  apiKey: "AIzaSyDpvvet78X0C9KipfY0pTat2qbgrMvQg9Q",
  authDomain: "tockesn-one.firebaseapp.com",
  projectId: "tockesn-one",
  storageBucket: "tockesn-one.appspot.com",
  messagingSenderId: "846737508575",
  appId: "1:846737508575:web:a0fec71f8809fc43c0c16a",
  measurementId: "G-8Y4PFCCWYY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber };
