import React, { useState } from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/Tc.css";

function RefundPolicy() {
  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <h1>Returns and Refunds Policy</h1>
          <div className="TC-Text">
            <div className="TermsCondtnsBox">
              <ol>
                <li>
                
                  <div>
                  <h3>Returns and Refunds Policy</h3>
                    <div>
                    Thank you for choosing Tockens.com, a service provided by XENFORD INTERNET PRIVATE LIMITED. Please read this policy carefully to understand our stance on returns and refunds
                    </div>
                    <h3>No Returns and No Refunds</h3>
                    <div>
                    All sales and transactions made on Tockens.com are final. We do not accept returns or provide refunds for any products or services, including but not limited to:
                    <ul>
                      <li>Purchases of goods</li>
                      <li>Entries for contests</li>
                      <li>Subscription fees for promotional services</li>
                    </ul>
                    </div>

                    <h3>Exceptions</h3>
                    <div>
                    In exceptional cases where a transaction error occurs due to technical issues on our platform, please contact our support team. We will review the issue on a case-by-case basis and take appropriate action.
                  
                    </div>
                    <h3>Contact Us</h3>
                    <div>
                    If you have any questions or concerns regarding our Returns and Refunds Policy, please contact us at: bbsupport@xenford.com
                  
                    </div>
                  </div>
             
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
