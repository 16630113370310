import React, { useState, useEffect } from 'react';
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams } from "react-router-dom";
import { baseURL, getDeal } from "../services/Aut.post.js";
import axios from "axios";

function OrderID() {
  const accessTokenforReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenforReferal.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };
  const { unq_id } = useParams();

  const [getDealOne, setGetDealOne] = useState('');
  const [getDealId, setGetDealId] = useState('');
  const [orderId, setOrderId] = useState("");
  const [errors, setErrors] = useState({});

  // Fetch the deal data when the component mounts or unq_id changes
  useEffect(() => {
    const fetchDeal = async () => {
      try {
        // Fetch the deal data
        const data = await getDeal(unq_id, btoken);
        console.log("data", data);
        setGetDealOne(data);
        setGetDealId(data.id);
      } catch (error) {
        console.error("Error fetching deal:", error);
      }
    };

    fetchDeal();
  }, [unq_id, btoken]); // Dependency array ensures the effect runs when unq_id or btoken changes

  // Handle input change
  const handleChange = (e) => {
    setOrderId(e.target.value);
  };

  const validate = () => {
    const newErrors = {};
    if (!orderId) {
      newErrors.orderId = 'Application No is required';
    }
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        // Check if the deal is active before proceeding
        if (!getDealOne.activeDeal) {
          window.location.href = `/Deals-error`;
          return; // Exit early if the deal is inactive
        }

        // Proceed with form submission if the deal is active
        const formData = {
          order_application_id: orderId,
          dealid: getDealId, // Use the deal ID from the state
        };

        // Submit the form
        const response = await axios.post(
          `${baseURL}/tockens_api/set_deal_orders/`,
          formData,
          { headers: headers }
        );

        console.log("response:", response.data.message); // Log the success message
        // alert(response.data.message);
        window.location.href = `/confirm-page/${unq_id}`;
      } catch (error) {
        console.error("Error:", error.response ? error.response.data : error.message);
      }
    }
  };

  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
            <div className="claim-page-header">
              <img
                src={getDealOne.marketplace_image ? `${baseURL}/${getDealOne.marketplace_image}` : amazon}
                alt={getDealOne.unq_id || "product"}
              />
            </div>
            <div>
              <h4>Enter your Order ID or Application No.</h4>
              <h3>
                <input
                  type="text"
                  placeholder="Order ID/Application No."
                  className="text-field-1"
                  onChange={handleChange}
                  value={orderId}
                />
              </h3>
              {errors.orderId && <p style={{ color: 'red' }}>{errors.orderId}</p>}
              <button className="button-orange-1 w-80" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
          <div className="backhome">
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderID;
