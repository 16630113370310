import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const MyComponent = () => {
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    const next = searchParams.get('next');
    const redirect = searchParams.get('redirect');
    const tocken = searchParams.get('tocken');
    
    if (next) {
      alert(next);
 
      const url = new URL(next);

      // Get the base URL (origin)
      const baseUrl = url.origin;

      // Get the pathname and remove the leading '/'
      const path = url.pathname.substring(1);
      alert(baseUrl)
      alert(path)

      alert(redirect);
      alert(tocken);

      
    }else{
      alert("Zero")
    }
  }, [searchParams]);

  return (
    <div>
      <h1>My Component</h1>
    </div>
  );
};

export default MyComponent;
