import "./Styles/Login.css";
import { createPortal } from "react-dom";
import { useState } from "react";
import ModalContent from "./ModalContent.js";
import HomeSlider from "./HomeSlider";
import TopCategories from "./TopCategories";
import ExtraOffers from "./ExtraOffers";

const Login = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="top-space-1 ">
        <HomeSlider />

        <div className="wrapper top-categories-sec-1">
          <h2>Top Categories</h2>
        </div>
        <TopCategories />

        <div className="wrapper top-categories-sec-1">
          <h2>Extra offers, earn more tockens</h2>
        </div>
        <ExtraOffers />
      </div>

      {showModal &&
        createPortal(
          <ModalContent onClose={() => setShowModal(false)} />,
          document.body
        )}
    </>
  );
};
export default Login;
