import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { Link } from "react-router-dom";
import "./Styles/ContactUs.css";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";

function ContactUs() {
  
  const [countries, setCountries] = useState([]);
  
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
 
  const [formData, setFormData] = useState({
    profile: {
      country_of_residence: "",
      city_of_residence: "",
      pincode: "",
      full_address: "",
      citizenship: "",
    },
  });
  const handleInputChange = (e) => {
   
    const { name, value } = e.target;
   
    if (name === "profile.country_of_residence") {
     
      setFormData({
        profile: {
          country_of_residence: value,
          city_of_residence: "",
          pincode: "",
          full_address: "",
          citizenship: "",
        },
      });
    }

    else if (name.startsWith("profile.")) {
      const profileField = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile: {
          ...prevFormData.profile,
          [profileField]: value,
        },
      }));
    } else {
      // Otherwise, update the top-level state
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
 
  };


 
useEffect(() => {
   
  }, []);

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Contact US </h1>

<div className="contact-address-wrap">
  <div className="contact-address-1">
  <div>
         
            <h4>Address:</h4> 5/12, PKpalli,<br></br>Prakasham, Andhra Pradesh,<br></br>
            Pin code: 523211
          </div>


  </div>
  <div className="contact-address-2">
    
    <div>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
    </div>

    <div>
            
            <Form onSubmit={handleSubmit}>
              <div className="Contact-form-wrap">
                <div className="reg-1">
                  <b>Name</b>
                
                  <input
                    type="text"
                    name=""
                   
                    className="text-f-1"
                  />
                </div>

                <div className="reg-1">
                  <b>E-mail</b>
                
                  <input
                    type="text"
                    name=""
                    className="text-f-1"
                  />
                </div>

                <div className="reg-1 w-100">
                  <b>Phone</b>
              
                  <input
                    type="text"
                    name=""
                    className="text-f-1"
                  />
                </div>

                <div className="reg-1 w-100">
                  <b>Message</b>
                  <textarea className="text-f-1"></textarea>
                
                </div>
                <div className="reg-1 w-100 text-center">
                  <button className="btn-2">Submit</button> &nbsp;&nbsp;
                  <Link to="/MyAccountStatus">
                    {" "}
                    <button className="btn-1">Cancel</button>
                  </Link>
                </div>
              </div>
            </Form>
          </div>

  </div>
</div>
          
          

         
        </div>
        <div>

          
        </div>
      </div>
    </div>
  );
}
export default ContactUs;
