// import React, { useState } from "react";
import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import { baseURL } from "../services/Aut.post.js";
import {  useNavigate } from "react-router-dom";

function GameStepTwo() {
  const contest_name = JSON.parse(localStorage.getItem("contest_name"));
  const sponcer_name = JSON.parse(localStorage.getItem("sponcer_name"));
  const gamedemolink = JSON.parse(localStorage.getItem("game_demo_link"));

  const winningtype = JSON.parse(localStorage.getItem("winningtype"));
  const prize = JSON.parse(localStorage.getItem("prize"));
  const AnnouncementDate = JSON.parse(localStorage.getItem("AnnouncementDate"));
  const No_of_winners = JSON.parse(localStorage.getItem("No_of_winners"));
  const stageno = JSON.parse(localStorage.getItem("stageno"));
  const moves = JSON.parse(localStorage.getItem("moves"));
 
  const urlParams = new URLSearchParams(window.location.search);
  const gameValue = urlParams.get("contest");

  const navigate = useNavigate();
  
  const [contestData, setContestData] = useState([]);

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const onClickLunch = () => {
    navigate("/survey");
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/userapi/  /`, null, {
          headers,
        });
        setContestData(response.data); // Update the state with fetched data
      } catch (error) {
        console.error("Error fetching contests:", error);
        // Handle error gracefully
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array

  const fetchGameSettings = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/userapi/usersingupvaluesview/`
      );
      console.log(response.data);

      localStorage.setItem("gridcount", response.data.grid);
    } catch (error) {
      console.error("Error fetching game settings:", error);
    }
  };
  useEffect(() => {
    fetchGameSettings(); // Fetch dynamic settings when the component mounts
  }, []);

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          <h1>{contest_name}</h1>
          <h6>Sponsored by {sponcer_name}</h6>
          <div className="DashboardSlider">
            <div className="video-wrap-main">
              <div className="video-wrap-1">
                <p>{prize}</p>

                <ul>
                  <li>winningtype : {winningtype}</li>
                  <li>AnnouncementDate : {AnnouncementDate}</li>
                  <li>Total No of Winners: {No_of_winners}</li>
                  <li>no of stages:{stageno}</li>
                  <li>no of lives :{moves}</li>
                </ul>
              </div>
              <div className="video-wrap-2">
                <h4>How to Play</h4>
                <iframe
                  width="100%"
                  height="315"
                  src={gamedemolink}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="TermsConditionsBox">
              <div className="form-group">
                <input type="checkbox" id="gameTandc" />
                <label htmlFor="gameTandc">Game Terms & Conditions</label>
              </div>
            </div>

            <div className="TermsConditionsBox">
              <div className="form-group">
                <input type="checkbox" id="bigbTandc" />
                <label htmlFor="bigbTandc">BigBonanza Terms & Conditions</label>
              </div>
            </div>

            <div className="text-center pt-3 pb-5">
              <button onClick={onClickLunch} className="btn-2">
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameStepTwo;
