// import React, { useState } from "react";
import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import axios from "axios";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import { baseURL } from "../services/Aut.post.js";
// import GameImg from './images/game-img-1.png'
import { Link, useNavigate , useLocation} from "react-router-dom";

function GameStepOne() {
  const [gameval, setgameval] = useState();
  const [addid, setAddid] = useState();
  const [sponsoredData, setSponsoredData] = useState([]);
  const location = useLocation();
  const { uniqueId } = location.state || {};
 
  const { uniqueName } = location.state || {};
 
  // const accessToken = JSON.parse(localStorage.getItem("user"));
  // const btoken = accessToken.access;
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${String(btoken)}`,
  // };



  useEffect(() => {
    // Parse the URL and get the value of the 'game' parameter
    const urlParams = new URLSearchParams(window.location.search);
    const gameValue = urlParams.get('contest');
    setgameval(gameValue)
    const formData = {
     
      contestid: gameValue,
      
    };
    
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/userapi/contestsponser/`,
          formData,
          {  }
        );
        localStorage.setItem("contest_name", JSON.stringify(response.data.spoContestName));
        localStorage.setItem("sponcer_name", JSON.stringify(response.data.sponsorby));
        localStorage.setItem("game_demo_link", JSON.stringify(response.data.gamedemolink));

        localStorage.setItem("winningtype", JSON.stringify(response.data.winningtype));

        localStorage.setItem("prize", JSON.stringify(response.data.prize));

        localStorage.setItem("AnnouncementDate", JSON.stringify(response.data.AnnouncementDate));

        localStorage.setItem("No_of_winners", JSON.stringify(response.data.No_of_winners));

        localStorage.setItem("stageno", JSON.stringify(response.data.stageno));
        localStorage.setItem("moves", JSON.stringify(response.data.moves));
        localStorage.setItem("time", JSON.stringify(response.data.time));
        setSponsoredData(response.data)
       
        setAddid(response.data.spoAdid)
        console.log(response.data); // Update the state with fetched data
      } catch (error) {
        console.error("Error fetching contests:", error);
        // Handle error gracefully
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array

  const navigate = useNavigate();

  const handleClick = () => {
    if(uniqueName){
      navigate(`/Survey-details/${uniqueId}/${uniqueName}`);
    }else{
      navigate(`/claim-page/${uniqueId}`);
    }
    
  };



  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          {/* <h1>{sponsoredData.spoContestName} </h1> */}
          <h6>Sponsored by {sponsoredData.sponsorby}</h6>
          <div className="DashboardSlider">
           {/* <img src={GameImg} alt="game banner" /> */}
          <a href={sponsoredData.sponsorlink} target="_blank"> 
            <img src={`${baseURL}/media/${sponsoredData.sponsorimage}`} alt="contest"/></a>
           {/* <p>{sponsoredData.sponsormsg}</p> */}
           <div className="text-center pt-3 pb-5">
             
             {/* <Link to="/GameStepTwo?game="+{gameValue} className="btn-2"> Proceed</Link> */}

            
          <button onClick={handleClick} className="btn-2">Proceed</button>
             

             </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameStepOne;
