import React, { useState } from "react";
import InnerBanner from "./InnerBanner";

import "./Styles/ContactUs.css";
import "./Styles/Subscriptions.css";


function SubNew() {
  const products = [
    {
      id: 1,
      name: "Clubby",
      price: "999",
      shortdescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
      id: 2,
      name: "Citizen",
      price: "2222",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: 3,
      name: "Champion",
      price: "555",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: 4,
      name: "Celebrity",
      price: "555",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];

  const [selectedProduct, setSelectedProduct] = useState(null);

  const openPopup = (product) => {
    setSelectedProduct(product);
  };

  const closePopup = () => {
    setSelectedProduct(null);
  };
  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Subscripition </h1>

          <div className="Subscriptions-wrap">
            {products.map((product) => (
              <div key={product.id} className="Subscriptions-box">
                <h3>{product.name}</h3>
                <ul>
                  <li>{product.description}</li>
                </ul>

                <h3>₹. {product.price}</h3>

                <button
                  className="btn-2 btn btn-primary btn-sm"
                  onClick={() => openPopup(product)}
                >
                  Buy Now
                </button>
              </div>
            ))}
          </div>

          {selectedProduct && (
            <div className="modal-1 fade show" style={{ display: "block" }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{selectedProduct.name}</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={closePopup}
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>{selectedProduct.description}</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
}
export default SubNew;
