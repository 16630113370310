import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams } from "react-router-dom";
import { baseURL, getDeal } from "../services/Aut.post.js";

function ClaimPage() {
  const accessTokenforReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenforReferal?.access;
  
  const [openUrl, setOpenUrl] = useState("");
  const { unq_id } = useParams(); 

  // Function to handle button click
  const handleClick = () => {
    if (openUrl) {
      // Open the affiliate link in a new tab
      window.open(openUrl, "_blank");
    } else {
      console.error("Affiliate link not found.");
    }
    
    // Redirect the user to the order ID page after opening the affiliate link
    window.location.href = `/order-id/${unq_id}`;
  };

  const [getDealOne, setGetDealOne] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch deal data and set the affiliate link
        const data = await getDeal(unq_id, btoken);
        setGetDealOne(data);
        setOpenUrl(data.affiliate_link); // Set the affiliate link to state
      } catch (error) {
        console.error("Error fetching deal:", error);
      }
    };

    fetchData();
  }, [unq_id, btoken]);

  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
            <div className="claim-page-header">
              <img
                src={
                  getDealOne.marketplace_image
                    ? `${baseURL}${getDealOne.marketplace_image}`
                    : amazon
                }
                alt={getDealOne.unq_id || "product"}
              />
            </div>

            <h3>{getDealOne.tockens_benefit}</h3>

            <button className="button-orange-1 w-80" onClick={handleClick}>
              Earn {getDealOne.visited_tockens} Tokens
            </button>
          </div>

          <div className="backhome">
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimPage;
