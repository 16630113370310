import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import './Styles/SignUp.css'
import { Form } from "react-bootstrap";
import Banner from './Banner';
import axios from "axios";
import { getReferralcode, baseURL } from '../services/Aut.post.js';


const SignUp = ({ handleLogin }) => {
  const location = useLocation();

  const [userData, setUserData] = useState(null);
  const [formError, setformError] = useState(null);

  const userPhoneNumber = JSON.parse(localStorage.getItem("userphonenumber"));

  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('IN');
  const [getValRef, setGetValRef] = useState([]);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    date_of_birth: '',
    gender: '',
    country_of_residence: 'IN',
    city_of_residence: '',
    pincode: '',
    full_address: '',
    refer_user_id: '',
    citizenship: '',
    signup_status: true,
    phone_number: userPhoneNumber,

  });

  const handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    // If the input field is nested under 'profile', update the nested state
    if (name.startsWith('profile.')) {
      const profileField = name.split('.')[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile: {
          ...prevFormData.profile,
          [profileField]: value,

        },

      }));
    } else {
      // Otherwise, update the top-level state
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };





  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${String(btoken)}`,
    }
    try {
      //const response = await axios.post(`${baseURL}/userapi/setsingupvalues/`, 
      const response = await axios.post(`${baseURL}/userapi/singupvaluespost/`,
        formData, {
        headers: headers
      });

      // Handle the response as neededx
      localStorage.removeItem("userLoginStatus");
      setUserData(response.data); // Update user data in state
      localStorage.setItem("user", JSON.stringify(response.data));
      const token = JSON.stringify(response.data);
      handleLogin(token);
      navigate("/Dashboard");
    } catch (error) {
      if (error.response.data.error) {
        setformError(error.response.data.error)
      } else {
        setformError("Error submitting form: Please check email, user with this email already exists.")
      }
      console.log(error)
    }
  };

  //Get all Contry data for Country of Residence
  const [countries, setCountries] = useState([]);
  const fetchCountryData = async () => {
    try {
      const response = await axios.post(`${baseURL}/userapi/getcountryview/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching country data:', error);
      throw error; // Propagate the error for the component to handle
    }
  };

  const formDataRef = {
    'phoneNumber': userPhoneNumber
  }

  const fetchDataRefdata = async () => {
    try {
      const data = await getReferralcode(formDataRef);
      setGetValRef(data)
      console.log("User Account Status:", data);
    } catch (error) {
      // Handle error if needed
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const countryData = await fetchCountryData();
        setCountries(countryData);

      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };
    fetchDataRefdata();
    fetchData();


  }, []);



  return (
    <div>
      <div className='Signup-Bg'>
        <div className="Signup-wrap">
          <Form onSubmit={handleSubmit}>
            <div className="Signup-Header-Text">
              <h2>Signup</h2>
              <h6>To complete the signup process, please enter the information below.</h6>
            </div>
            {formError ? (
              <p>{formError}</p>

            ) : (
              <span></span>

            )}

            <div className="reg-wrap">

              <div className='reg-1'>
                <b>First Name</b>

                <input
                  type='text'
                  name='first_name'
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className='text-f-1'
                  required
                />
              </div>
              <div className='reg-1'>
                <b>Last Name</b>

                <input
                  type='text'
                  name='last_name'
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className='text-f-1'
                  required
                />
              </div>
              <div className='reg-1'>
                <b>Email</b>

                <input
                  type='text'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  className='text-f-1'
                  required
                />
              </div>
              <div className='reg-1'>
                <b>Date of Birth</b>
                <input
                  type='date'
                  name='date_of_birth'
                  value={formData.date_of_birth}
                  onChange={handleInputChange}
                  className='text-f-1'
                  required
                />
              </div>
              <div className='reg-1'>
                <b>Gender</b>

                <select id="mySelect" className='text-f-1' name="gender" value={formData.gender} onChange={handleInputChange} required>
                  <option value="">Select</option>
                  <option value={'male'}>Male</option>
                  <option value={'female'}>Female</option>

                </select>
              </div>
              <div className='reg-1'>
                <b>Country of Residence</b>
                <select

                  name="country_of_residence"
                  value={selectedValue}
                  onChange={handleInputChange}
                  className="text-f-1"
                  required
                >
                  {countries.map(country => (
                    <option value={country.country_code}  >
                      {country.country_name}
                    </option>
                  ))}
                </select>

              </div>
              <div className='reg-1'>
                <b>City of Residence</b>

                <input
                  type='text'
                  name='city_of_residence'
                  value={formData.city_of_residence}
                  onChange={handleInputChange}
                  className='text-f-1'
                />
              </div>
              <div className='reg-1'>
                <b>Pincode / Zipcode</b>

                <input
                  type='text'
                  name='pincode'
                  value={formData.pincode}
                  onChange={handleInputChange}
                  className='text-f-1'
                />
              </div>
              <div className='reg-1 w-100'>
                <b>Full Address</b>

                <input
                  type='text'
                  name='full_address'
                  value={formData.full_address}
                  onChange={handleInputChange}
                  className='text-f-1'
                />
              </div>
              <div className='reg-1'>
                <b>Referral Code </b>


                {getValRef && getValRef.referral_code ? (


                  <input
                    type='text'
                    onChange={handleInputChange}
                    value={getValRef.referral_code}
                    name='refer_user_id'
                    className='text-f-1'
                    readOnly={true}
                  />
                ) : (



                  <input
                    type='text'
                    name='refer_user_id'
                    value={formData.refer_user_id}
                    onChange={handleInputChange}
                    className='text-f-1'

                  />

                )}

              </div>
              <div className='reg-1'>
                <b>Citizenship</b>


                <input
                  type='text'
                  name='citizenship'
                  value={formData.citizenship}
                  onChange={handleInputChange}
                  className='text-f-1'
                />
              </div>
              <div className='reg-1 w-100 text-center'>

                <button className='Signup-button'>Signup</button>





              </div>

            </div>
          </Form>

        </div>


      </div>
      <Banner />
    </div>
  )
};

export default SignUp
