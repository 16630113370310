import React, { useEffect, useState } from "react";
import {baseURL, getAllResultDetailsThirdarrg } from "../services/Aut.post.js";
import { useParams } from "react-router-dom";
import axios from 'axios';
import "./Styles/ExtraOffers.css";

const SurveyDetails = () => {

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const { id, cname } = useParams();
  
  const [survey, setSurvey] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [imageUploads, setImageUploads] = useState({});
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllResultDetailsThirdarrg(
          "surveystwo",
          id,
          cname
        );
        console.log("DATA:", data);
        if (data && data.title) {
          setSurvey(data);
        } else {
          console.error("Invalid data format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, cname]);

  const handleOptionChange = (questionId, optionText) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [questionId]: optionText,
    }));
  };

  const handleTextChange = (questionId, text) => {
    setTextAnswers((prevState) => ({
      ...prevState,
      [questionId]: text,
    }));
  };

  const handleImageUpload = (questionId, event) => {
    const file = event.target.files[0];
    setImageUploads((prevState) => ({
      ...prevState,
      [questionId]: file,
    }));
  };

  const renderMultipleChoice = (question) => (
    <div>
      <select
        id={`dropdown-${question.question_id}`}
        value={selectedOptions[question.question_id] || ""}
        onChange={(e) =>
          handleOptionChange(question.question_id, e.target.value)
        }
      >
        <option value="">Select an option</option>
        {question.options &&
          question.options.map((option, index) => (
            <option key={index} value={option.option_text}>
              {option.option_text}
            </option>
          ))}
      </select>
    </div>
  );

  const renderRadioOptions = (question) => (
    <div>
      {question.options &&
        question.options.map((option, index) => (
          <div key={index}>
            <input
              type="radio"
              id={`radio-${index}-${question.question_id}`}
              name={question.question_id}
              value={option.option_text}
              checked={
                selectedOptions[question.question_id] === option.option_text
              }
              onChange={() =>
                handleOptionChange(question.question_id, option.option_text)
              }
            />
            <label htmlFor={`radio-${index}-${question.question_id}`}>
              {option.option_text}
            </label>
          </div>
        ))}
    </div>
  );

  const renderTextInput = (question) => (
    <div>
      <input
        type="text"
        id={`text-${question.question_id}`}
        value={textAnswers[question.question_id] || ""}
        onChange={(e) => handleTextChange(question.question_id, e.target.value)}
      />
    </div>
  );

  const renderImageUpload = (question) => (
    <div>
      <input
        type="file"
        id={`image-${question.question_id}`}
        accept="image/*"
        onChange={(e) => handleImageUpload(question.question_id, e)}
      />
    </div>
  );

  const renderOptions = (question) => {
    switch (question.question_type) {
      case "multiple_choice":
        return renderMultipleChoice(question);
      case "radio":
        return renderRadioOptions(question);
      case "text":
        return renderTextInput(question);
      case "image":
        return renderImageUpload(question);
      default:
        return null;
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(selectedOptions)
    console.log(textAnswers)
   

    const responses = {
      selectedOptions,
      textAnswers,
      imageUploads,
    };
    console.log(responses)
    // if(selectedOptions){
    //   responses = selectedOptions
    // }else{
    //   responses = textAnswers
      
    // }
    // console.log(responses)
    axios.post(`${baseURL}/tockens_api/surveyresponse/${id}/${cname}`, responses,{ headers: headers })
      .then(response => {
        alert('Response submitted successfully');
      })
      .catch(error => {
        console.error('Error submitting response:', error);
      });
  };
  return (
    <div className="top-space-1">
      <div className="extra-offer-wrap">
        <h1>Survey's</h1>
        <form onSubmit={handleSubmit}>
        <div>
          {survey ? (
            <div>
              <h2>{survey.title}</h2>
              <p>{survey.description}</p>
              <div>
                {survey.questions.map((question) => (
                  <div key={question.question_id} className="survey_container">
                    <div className="survey-item-wrap">
                      {question.question_text}
                    </div>
                    {renderOptions(question)}
                  
                  </div>
                ))}
              </div>
              <button className = "survey-btn" type = "submit" >Save</button>

              
            </div>
          ) : (
            <p>Loading survey details...</p>
          )}
        </div>
        </form>
      </div>
    </div>
  );
};

export default SurveyDetails;
