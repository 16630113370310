
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Styles/Login.css";
import Carousel from "react-bootstrap/Carousel";
import { getBannerImages, getBannerAnimationValues, baseURL } from "../services/Aut.post.js";

function Banner() {

  const [bannerData, setBannerData] = useState([]);
  const [aimationPropData, setAimationPropData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBannerImages();
        setBannerData(data);

      } catch (error) {

      }
    };

    const fetchDataAnimationProp = async () => {
      try {
        const data = await getBannerAnimationValues();
        setAimationPropData(data);

      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
    fetchDataAnimationProp();
  }, []);
  return (
    <div>
      <div className="login-bg">
        <div className="wrapper">
          <Carousel fade controls={false} interval={aimationPropData.interval}>

            {bannerData.map((getBannerData) => (




              <Carousel.Item>
                <div className="login-wrap">
                  <div className="login-1">
                    <h1>
                      {getBannerData.banner_text}
                    </h1>
                    <Link to={getBannerData.banner_link} className="btn-3">
                      Explore Now
                    </Link>

                  </div>
                  <div className="login-2">
                    <img src={`${baseURL}${getBannerData.banner_image}`} alt="BannerImg2" />
                  </div>
                </div>
              </Carousel.Item>
            ))}

          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Banner;
