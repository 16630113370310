import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { baseURL, getDealsAll } from "../services/Aut.post.js";

function ExtraOffers() {
  const [allDeals, setAllDeals] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDealsAll();
        setAllDeals(data);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, []);

      // Function to handle the item click and navigate with state
      const onClickItem = (value) => {
        navigate("/advertisement", {
          state: { uniqueId: value.unq_id }, // Pass the uniqueId via state
        });
      };
  

  return (
    <>
      <div className="extra-offer-wrap">
        <div className="extra-offer-flex">
          {allDeals.length > 0 ? (
            allDeals.map((value, index) => (
              <div className="extra-offer-2" key={index}>
                <div className="extra-offer-2-img">
                  <img
                    src={
                      value.marketplace_image
                        ? `${baseURL}${value.marketplace_image}`
                        : amazon
                    }
                    alt={value.marketplace_image || "product"}
                    style={{ height: "80px" }}
                  />
                </div>
                <button onClick={() => onClickItem(value)}>
                  Earn {value.affiliated_tockens} Tockens
                </button>
              </div>
            ))
          ) : (
            <div>loading</div>
          )}
        </div>
      </div>
    </>
  );
}

export default ExtraOffers;
