import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner.js";
import "./Styles/ContactUs.css";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";

function TokenTransition() {
  const [orders, setOrders] = useState([]); // State to store order data
  const [filteredOrders, setFilteredOrders] = useState([]); // State to store filtered orders
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const [errorMessage, setErrorMessage] = useState(""); // State to handle error messages
  const [tokens, setTokens] = useState(0); // State to store tokens
  const [noTransactionsMessage, setNoTransactionsMessage] = useState(""); // State to handle no transactions message



  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  // console.log("HEADERS" , headers)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/tockens_api/list_tocken_withdraw_and_credit/`, {
          headers: headers, // Set headers correctly
          // If you need to send any query parameters, you can do so here
        });
  
        console.log("API Response:", response.data);
        // alert(response.data); // Use alert to show API response
        setOrders(response.data.tockens);
        setFilteredOrders(response.data.tockens); // Initially set filteredOrders to all orders
        setLoading(false); // Loading complete
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        alert("Error fetching data. Please try again."); // Alert for errors
      }
    };
  
    fetchData();
  }, []);
  

  // Date validation and filtering function
 // Date validation and filtering function
const handleSearch = () => {
  if (!startDate || !endDate) {
    setErrorMessage("*Both start and end dates are required.");
    return;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if end date is earlier than start date
  if (end < start) {
    setErrorMessage("*End date cannot be earlier than start date.");
    return;
  }

  setErrorMessage(""); // Clear any previous error

  // Convert dates to "year-month-day" format
  const formattedStartDate = start.toISOString().split("T")[0]; // YYYY-MM-DD
  const formattedEndDate = end.toISOString().split("T")[0]; // YYYY-MM-DD

  console.log("Formatted Start Date:", formattedStartDate);
  console.log("Formatted End Date:", formattedEndDate);

  // Make the API request with dates in the URL path
  axios.get(`${baseURL}/tockens_api/list_tocken_withdraw_and_credit/${formattedStartDate}/${formattedEndDate}/`, {
    headers: headers, // Set headers correctly
  })
  .then((response) => {
    const transactions = response.data.tockens;

    if (transactions && transactions.length > 0) {
      // Update orders if data is found
      setOrders(transactions);
      setFilteredOrders(transactions); // Update the filtered orders
      setNoTransactionsMessage(""); // Clear no transactions message
    } else {
      // No data found in the response
      setOrders([]); // Clear orders
      setFilteredOrders([]); // Clear filtered orders
      setNoTransactionsMessage(`No transactions found between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`);
    }
  })
  .catch((error) => {
    // Handle error and show message for failed API call
    setOrders([]); // Clear any previous orders
    setFilteredOrders([]); // Clear filtered orders
    setNoTransactionsMessage(`No transactions are available between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`);
    console.error("There was an error fetching the data!", error);
  });
};


  // Clear filter and show all orders
  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setErrorMessage("");
    setNoTransactionsMessage(""); // Clear any messages
  
    // Reset filtered orders back to all orders
    setFilteredOrders([]);
  
    // Fetch all the original orders again from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/tockens_api/list_tocken_withdraw_and_credit/`, {
          headers: headers, // Set headers correctly
        });
  
        // Set all orders and filtered orders back to the original data
        setOrders(response.data.tockens);
        setFilteredOrders(response.data.tockens); // Reset to all orders
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        alert("Error fetching data. Please try again."); // Alert for errors
      }
    };
  
    // Call fetchData to reload the data
    fetchData();
  };
  
  
  //blance tockens api 

  useEffect(() => {
    const fetchHomeDetails = async () => {
      try {
  
        const response = await axios.get(`${baseURL}/userapi/gethomedetails/`, {
          headers: headers,
        })

        // Assuming the API response structure is as you mentioned
        const {  tockenslist} = response.data;

        // Update state variables
        
        setTokens(tockenslist);
        
      } catch (error) {
     // console.error("There was an error fetching the home details!", error);
        setErrorMessage("Error fetching data. Please try again."); // Set error message
      } 
    };

    fetchHomeDetails(); // Call the fetch function
  }, [headers]);

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <div className="wrapper-sec-1">
            <h1>Tockens Transition</h1>
            <h2 className="tbalnce">Balance Tockens : <span className="tockens-box">{tokens}</span> </h2>
          </div>
          <div className="search-i-main">


            <div className="search-i-1">
              <h5>Start Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="search-i-1">
              <h5>End Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>


            <div className="search-i-1">
              <div className="search-btns">
                <button className="button-search" onClick={handleSearch}>
                  Search
                </button>
                <button className="button-clear" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
          </div>

          {/* Display error message */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {/* Display the selected start and end date */}
          {startDate && endDate && !errorMessage && (
            <div>
              <p>
                Showing orders from <strong>{new Date(startDate).toLocaleDateString()}</strong> to <strong>{new Date(endDate).toLocaleDateString()}</strong>
              </p>
            </div>
          )}

           {/* Display no transactions message
           {noTransactionsMessage && (
            <p className="no-transactions-message">{noTransactionsMessage}</p>
          )} */}

          {/* Display filtered orders */}
          <div className="main-order-block">
            <div className="your-order-main">
              <div className="your-order-1-main">
                <ul>
                  <li className="your-order-1-1">Transition Date</li>
                  <li className="your-order-1-1">Action Type</li>
                  <li className="your-order-1-1">Tocken</li>
                  <li className="your-order-1-1">Withdraw Type</li>
                </ul>
              </div>
            </div>

            <div className="orders-main-list">
              {loading ? (
                <p className="Loading">Loading...</p>
              ) : filteredOrders.length > 0 ? (
                filteredOrders.map((order, index) => (
                  <div key={order.credit_for_id || index} className="your-order-main-list"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFF6F2" : "#ffffff", // Alternating colors
                    }}
                  >
                    <div className="your-order-2-main">
                      <div className="your-order-1-1 date">
                        {new Date(order.add_date_time).toLocaleDateString()}
                      </div>
                      <div className="your-order-1-1 order"> {order.tocken_type}</div>
                      <div className="your-order-1-1 tocken">{order.tocken}</div>
                      <div className="your-order-1-1 withdraw">
                        <span className={order.credit_type === 0 ? "debited" : "credited"}>
                          {order.credit_type === 0 ? "Debited" : "Credited"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                noTransactionsMessage && (
                  <p className="no-transactions-message" style={{textAlign : "center"}}>{noTransactionsMessage}</p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenTransition;
