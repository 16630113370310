import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { baseURL, getDealsCategoriesInner } from "../services/Aut.post.js";

function DealsCategoryInner() {
  const [allDeals, setAllDeals] = useState([]);
  const navigate = useNavigate();
  // Retrieve access token from local storage
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken?.access;

  // Get category ID and category name from route params
  const { id, cname } = useParams();

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pass the category ID and access token to the service
        const data = await getDealsCategoriesInner(id, btoken);
        setAllDeals(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchData();
  }, [id, btoken]);

    // Function to handle the item click and navigate with state
    const onClickItem = (value) => {
      navigate("/advertisement", {
        state: { uniqueId: value.unq_id }, // Pass the uniqueId via state
      });
    };

  const dealsName =   localStorage.getItem("selectedCategoryName");


  return (
    <> 
      <div className="top-space-1">
        <div className="extra-offer-wrap">
          <h1>{dealsName} Deals</h1> {/* Page Title with Category Name */}
          <div className="extra-offer-flex">
            {allDeals.length > 0 ? (
              allDeals.map((value, index) => (
                <div className="extra-offer-2" key={index}>
                   <div className="extra-offer-2-img">
                   <img
                    src={
                      value.marketplace_image
                        ? `${baseURL}${value.marketplace_image}`
                        : amazon
                    }
                    alt={value.marketplace_image || "product"}
                    style={{ height: "80px" }}
                  />
                  </div>
                 {/* <h6>
                    
                    <Link
                    to="/advertisement"
                    state={{ uniqueId: value.unq_id }} // React Router v6 syntax
                  >
                      Earn {value.affiliated_tockens} Tockens
                    </Link>
                  </h6> */}

                    <button onClick={() => onClickItem(value)}>
                    Earn {value.affiliated_tockens} Tockens
                  </button>
                </div>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DealsCategoryInner;
