import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link } from "react-router-dom";
import { baseURL, getDealsAll } from "../services/Aut.post.js";
function Deals() {
  const [allDeals, setAllDeals] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDealsAll();
        setAllDeals(data);
      } catch (error) {}
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="top-space-1">
        <div className="extra-offer-wrap">
          <h1>Deals</h1>
          <div className="extra-offer-flex">
            {allDeals.length > 0 ? (
              allDeals.map((value, index) => (
                <div className="extra-offer-1">
                  <img
                    src={
                      value.marketplace_image
                        ? `${baseURL}/${value.marketplace_image}`
                        : amazon
                    }
                    alt={value.marketplace_image || "product"}
                    style={{ height: "80px" }}
                  />
                  <h6>
                    {/* <Link to={`/claim-page/${value.unq_id}`}> */}
                    <Link
                    to="/advertisement"
                    state={{ uniqueId: value.unq_id }} // React Router v6 syntax
                  >
                      Earn {value.affiliated_tockens} Tockens
                    </Link>
                  </h6>
                </div>
              ))
            ) : (
              <div>loading</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Deals;
