
import React from 'react';
import "./Styles/ExtraOffers.css";
import successlogo from "./images/sucess-msg.png"

function OrderID() {
  
  const handleClick = () => {
    const redirectUrl = `/`;
    window.location.href = redirectUrl;
  };


  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
      
          <div className="claim-page-bg">
          <img src={successlogo} alt="logo" />
          <h4>Your order is successfully submitted!</h4>
          <h3>Thank you for shopping with us!</h3>
          <p>We are processing your order and will update you shortly.</p>
          <p>If you have any questions, feel free to contact our support team.</p>
            <h3>
       
            </h3>
            <button class="button-orange-1 w-80" onClick={handleClick}>Back to Home</button>
          </div>     
        </div>
      </div>
    </>
  );
}

export default OrderID;
