import React, { useEffect, useState } from 'react';
import { baseURL } from "./services/Aut.post.js";
const TitleUpdater = () => {
  const [title, setTitle] = useState('Loading...');

  useEffect(() => {
    fetch(`${baseURL}/userapi/getsitetext/`) 
      .then(response => response.json())
      .then(data => {
        if (data.GetHtext) {
          setTitle(data.GetHtext);
          document.title = data.GetHtext;
        }
      })
      .catch(error => {
        console.error('Error fetching title:', error);
      });
  }, []);

  return null; 
};

export default TitleUpdater;
