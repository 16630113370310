
import React, { useState, useEffect } from 'react';
import InnerBanner from "./InnerBanner";
import "./Styles/Winnings.css";
import AddressEditIcon from "./images/winning.png";
function Winnings() {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(true);
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };


  return (
    <div>
      {showPopup && (
        <div className="Winnings-popup">
          <div className="Winnings-popup-content">
            <h1>Disclaimer</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <button className='btn-2' onClick={handleClose}>Continue</button>
          </div>
        </div>
      )}
      <InnerBanner />
      <div className="Winnings-bg-1">
        <div className="wrapper">
          <h1>Winnings </h1>
          <h6>
          </h6>

          <h3>All the My Winnings</h3>
          <div className="Winnings-wrap">
            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">Best</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">Best</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">Best</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">Best</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">Best</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">Best</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

          </div>
          <button className="btn-2">View All</button>
          <hr />

          <h3>Recent Winners</h3>
          <div className="Select-Contest">
            <p>Select Contest</p>
            <select>
              <option>Select</option>
            </select>

          </div>
          <div className="Winnings-wrap">
            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">1</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">2</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">3</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">4</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">5</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>

            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              <div className="wb-2">6</div>
              <div className="wb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Winnings;
