import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/MyAccountStatus.css";
import { Link } from "react-router-dom";
import AddressEditIcon from "./images/AddressEditIcon.png";
import ActiveImg from "./images/active.png";
import axios from "axios";
import {
  getUserAccountStatus,
  baseURL,
  getUserRefCode,
  getPanaandAadhar
} from "../services/Aut.post.js";

import InviteModal from "./InviteModal";

function MyAccountStatus() {
  const [showModal, setShowModal] = useState(false);
  const [texts, setTexts] = useState([]);

  const accessTokenforReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenforReferal.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };
  const [panaadhar, setPanaadhar] = useState({

    aadharcard: "",
    pancard: "",


  });
  const [userAccountData, setUserAccountData] = useState([]);

  const [referal, setReferal] = useState();
  const [inviteCount, setInviteCount] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const formData = {
    // Your form data goes here
    referral_code: referal,
  };

  const handleTextChange = (event) => {
    setReferal(event.target.value);

  };
  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleUpdate = () => {

    setIsEditing(false);
    try {
      const response = axios.post(
        `${baseURL}/userapi/setreferralcode/`,
        formData,
        { headers: headers }
      );
      alert('Update processed successfully');

    } catch (error) {
      console.error("Error:", error.message)
    }
  };

  const accessToken = JSON.parse(localStorage.getItem("user")).access;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserAccountStatus(accessToken);
        setUserAccountData(data);
        console.log("User Account Status:", data);
      } catch (error) {
        // Handle error if needed
      }
    };

    const fetchDataRef = async () => {
      try {
        const data = await getUserRefCode(accessToken);
        setReferal(data.refdata.refferal_code);
        setInviteCount(data.countinv)
        console.log("User ref code:", data);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
    fetchDataRef();
  }, [accessToken]);

  useEffect(() => {


    const fetchData = async () => {
      try {
        const data = await getPanaandAadhar(btoken);
        console.log(data.pan_card);
        console.log(data.aadhar_card);
        setPanaadhar({

          aadharcard: data.aadhar_card,
          pancard: data.pan_card,

        });


      } catch (error) {
        // Handle error if needed
      }
    };


    fetchData();
  }, [btoken]);

  const invitepopuphandleAdd = (text) => {
    setTexts((prevTexts) => [...prevTexts, text]);
  };

  const invitepopuphandleOpenModal = () => {
    setShowModal(true);
  };

  const invitepopuphandleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1">
        <div className="wrapper">
          <h1>
            Status{" "}
            <span>
            </span>
          </h1>
          <h6>User Level: Beginner</h6>
          <div className="Address-wrap">
            <div className="Address-box Address-bg">
              <div className="Address-edit-icon">
                <Link to="/ChangeAddress?action=edit">
                  {" "}
                  <img src={AddressEditIcon} alt={AddressEditIcon} />
                </Link>
              </div>
              <h4>Address</h4>

              {userAccountData.map((profileData) => (
                <p>
                  {profileData.full_address}
                  <br />
                  {profileData.city_of_residence}, {profileData.pincode}
                  <br />
                  {profileData.country_of_residence}
                  <br />
                </p>
              ))}
            </div>
            <div className="Address-box-2">
              <div className="Address-bg">
                <div className="Address-edit-icon">
                  <Link to="/Subscriptions">
                    <img src={AddressEditIcon} alt={AddressEditIcon} />
                  </Link>
                </div>
                <h4>Subscription Status</h4>
                <div className="heading-2">Clubby</div>


              </div>

              <div className="Address-bg">
                <h4>Tockens</h4>
                <div className="heading-2">Tockens</div>
                <button className="TockensButton">Earn More</button>
              </div>



            </div>


            <div className="Address-box Address-bg">
              <div className="Address-edit-icon">
                <Link to="/ChangeAadharPan?action=edit">
                  <img src={AddressEditIcon} alt={AddressEditIcon} />
                </Link>
              </div>



              <div className="font-style-2"><span>Aadhar:</span>

                <b>{panaadhar.aadharcard}</b>



              </div>
              <div className="font-style-2"><span>Pan Card:</span>

                <b>{panaadhar.pancard}</b>




              </div>


            </div>


          </div>
          <hr />
          <h5>Refer and Earn:</h5>

          <div className="Refer-Earn">
            Get xxx when your friend subscribes with your referral code
            {isEditing ? (
              <input
                type="text"
                value={referal}
                onChange={handleTextChange}
                className="editfield"
              />
            ) : (
              <b>{referal}</b>
            )}

            {!isEditing && (
              <button onClick={handleEdit} className="editbutton">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </button>
            )}
            {isEditing && (
              <button onClick={handleUpdate} className="editbutton">
                <img src={ActiveImg} alt={ActiveImg} />
              </button>
            )}
          </div>
          <hr></hr>

          <div>
            <button
              onClick={invitepopuphandleOpenModal}
              className="TockensButton"
            >
              Invite
            </button>
            <InviteModal
              isOpen={showModal}
              onClose={invitepopuphandleCloseModal}
              onAdd={invitepopuphandleAdd}
              invCount={inviteCount}
            />
            <div>
              {texts.map((text, index) => (
                <div className="AccountPopupField" key={index}>
                  {text}
                </div>
              ))}
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default MyAccountStatus;
