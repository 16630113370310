import React from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/Tc.css";

function AboutUs() {
  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <h1>About Us</h1>
          <div className="TC-Text">
            <div className="TermsCondtnsBox">
              
              <ol>
                <li>
                  <h3>Tockens</h3>
                  <div>
                    <div>
                    Welcome to Tockens.com, your ultimate destination for innovative promotional marketing services! We are a proud venture of XENFORD INTERNET PRIVATE LIMITED, dedicated to revolutionizing the way businesses engage with their audience through the power of gaming and lucky draw contests.
                    </div>
                    <h3>Who We Are</h3>
                    <div>
                    Welcome to Tockens.com, your ultimate destination for innovative promotional marketing services! We are a proud venture of XENFORD INTERNET PRIVATE LIMITED, dedicated to revolutionizing the way businesses engage with their audience through the power of gaming and lucky draw contests.
                    </div>
                    <h3>Our Mission</h3>
                    <div>
                    Our mission is to transform traditional marketing methods by infusing them with fun and excitement. We aim to help businesses connect with their customers on a deeper level, fostering meaningful interactions and lasting impressions. By combining cutting-edge technology with our expertise in promotional strategies, we deliver campaigns that not only stand out but also deliver measurable results.
                    </div>
                    <h3>What We Offer</h3>
                    <div>
                <ul>
                  <li>
                  <strong>Engaging Games:</strong> Our suite of customizable games is designed to entertain and engage your audience. From simple puzzles to complex challenges, our games are tailored to match your brand’s identity and objectives.
                </li>
                <li>
                <strong>Lucky Draw Contests:</strong> Add an element of surprise to your promotions with our lucky draw contests. These contests are designed to incentivize participation and reward your audience, creating a buzz around your brand.
                </li>

                <li>
                <strong>Custom Solutions:</strong> We understand that every business is unique. That’s why we offer bespoke promotional solutions that align with your specific needs and goals. Whether you're launching a new product, building brand awareness, or driving customer loyalty, we have the tools and expertise to make it happen.
                </li>
                
                
                </ul>
                    </div>


                    <h3>Why Choose Us?</h3>   
                    <div>
                <ul>
                  <li> 

                  <strong>Innovative Approach:</strong> We stay ahead of the curve by constantly evolving our platform and services. Our team of creative minds and tech experts is always exploring new ways to make your promotions more engaging and effective.
                  </li>

                  <li> 

                  <strong>Proven Results:</strong>  Our track record speaks for itself. We’ve helped numerous brands achieve their marketing goals through our interactive campaigns and innovative solutions.
                  </li>


                  <li> 

                  <strong>Customer-Centric:</strong>  Your success is our priority. We work closely with you to understand your needs and deliver solutions that exceed your expectations.
                  </li>
                
                
                </ul>
                    </div>
                  </div>
                  <div>
           
					
                  </div>
                </li>
               
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
