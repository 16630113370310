// import React, {useState} from 'react'
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="relative-1">
      <div className="footer-bg">
        <div className="wrapper">
          <div className="footer-wrap">
            <div className="footer-1">
              &copy; Copyright 2024 XENFORD. All Rights Reserved.
            </div>
            <div className="footer-2">
              <div className="footerlinks">
                <ul>
                  <li>
                    <Link to="/Subscriptions"> Subscriptions</Link>
                  </li>
                  <li>
                    <Link to="/AboutUs"> About</Link>
                  </li>

                  <li>
                    <Link to="/ContactUs">Contact</Link>
                  </li>
                  <li>
                    <Link to="/RefundPolicy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/TermsandConditions">T&C</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
